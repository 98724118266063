import React from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
// import { AuthContext } from '../utils/firebaseContext'
import Spinner from '../components/spinner'
import { useLoginMutation, useSetImpersonateCompany, useSetReverToAdminRole, useSetSwitchToSuperRole } from '../utils/api'

const Test = () => {
  const [credentials, setCredentials] = React.useState({ username: '', password: '' })
  const loginMutation = useLoginMutation()
  const [tokenDetail, setTokenDetail] = React.useState(null)
  const switchToSuperMutation = useSetSwitchToSuperRole(setTokenDetail)
  const revertBackToUserRole = useSetReverToAdminRole(setTokenDetail)
  const impersonateCompanyMutation = useSetImpersonateCompany(setTokenDetail)
  // const authValue = React.useContext(AuthContext)

  // trigger login
  const doLogin = (e) => {
    e.preventDefault()
    loginMutation.mutate(credentials)
  }

  const elevateToSuperUser = () => {
    switchToSuperMutation.mutate()
  }

  const demoteToUserRole = () => {
    revertBackToUserRole.mutate()
  }

  const impersonateCompany = (companyName, companyId) => {
    impersonateCompanyMutation.mutate({ companyName, companyId })
  }

  return (<>

    <h5>Test Auth credential</h5>

    <div className="col-md-7 d-flex flex-center">
      <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5" style={{ minHeight: '300px' }}>
        <h3 className="mb-3 font-weight-bold">Login</h3>

        {/* form */}
        <Form className="d-flex flex-column flex-grow-1">

          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              // placeholder="Email address"
              autoComplete="username"
              autoFocus={true}
              value={credentials.username}
              onChange={e => setCredentials(ps => ({ ...ps, username: e.target.value }))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              // placeholder="Password"
              autoComplete="current-password"
              value={credentials.password}
              onChange={e => setCredentials(ps => ({ ...ps, password: e.target.value }))}
            />
          </Form.Group>

          <Form.Group>
            <Button
              className="btn-block mt-5"
              type="submit"
              disabled={loginMutation.isLoading || credentials.username.length === 0 || credentials.password.length === 0}
              onClick={(e) => doLogin(e)}
            >
              {loginMutation.isLoading ? <Spinner /> : <span>Login</span>}
            </Button>
          </Form.Group>

          {/* <div className="row justify-content-center align-items-center">
                          <a href="#">Forgot Password?</a>
                        </div> */}

          {loginMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Invalid username or password.'}</Alert>}

        </Form>

      </div>
    </div>

    <div>
      <Button
        className="btn-block mt-5"
        onClick={(e) => elevateToSuperUser(e)}>
        Switch to super admin
      </Button>
    </div>

    <div>
      <Button
        className="btn-block mt-5 btn-secondary"
        onClick={(e) => demoteToUserRole(e)}>
        Revert back to original role
      </Button>
    </div>

    <div>
      <Button
        className="btn-block mt-5 btn-info"
        onClick={(e) => impersonateCompany('ABC PTY LTD', '123bkkljk-32131nk-12312-b12312-dasda')}>
        Impersonate company ABC
      </Button>
    </div>

    <div className="card">
      <div className="card-body rounded-soft bg-white px-5 py-4">
        <h5 className="mt-0 mb-3">Welcome!</h5>
        <p className="mb-0" style={{ border: '1px solid lime' }}>{JSON.stringify(tokenDetail) || 'None'}</p>
        {/* <pre>{JSON.stringify(authValue)}</pre> */}
      </div>
    </div>
  </>)
}

export default Test
